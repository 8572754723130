.Bianca-pic{
    border: none;
}

.Bianca-pic:hover{
    padding: 12px;
    border: 5px solid green;
}

.box {
    cursor: pointer;
  }

.display-talk{
    width: 420px;
    min-height: 250px;
}


@media (max-width: 700px) {

    .name-title-container{
      margin: 0 auto;
      align-items: center;
    }
}
