@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  min-height: 100vh;
  min-width: 100vw;
  margin: 0;
  position: absolute;
  background-color: #000;
  align-items: center;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
  margin: 0;
}

.picture-side-container {
  color: white;
}
