.techskills-section-container {
  min-height: 300px;
}

.icon-skills {
  flex-wrap: wrap;
  font-size: 80px;
  align-items: center;
  padding: 30px 20px;
  gap: 30px 60px;
}

.html-icon {
  color: #e65100;
}

.css-icon {
  color: #0277bd;
}

.sass-icon {
  color: #f06292;
}

.bootstrap-icon {
  color: #673ab7;
}

.tailwind-icon {
  color: rgb(77, 198, 209);
}

.motion-icon,
.git-icon,
.figma-icon {
  color: black;
}

.js-icon {
  color: #f0e138;
}

.typescript-icon {
  color: #007acc;
}

.react-icon {
  color: #39c1d7;
}

.node-icon {
  color: #37d23a;
}

.nest-icon {
  color: rgb(217, 62, 62);
}

.python-icon {
  color: #646464;
}

.mongo-icon {
  color: #145f16;
}

.postgres-icon {
  color: #1f1f1f;
}

.docker-icon {
  color: #0db7ed;
}

.aws-icon {
  color: #1e3050;
}

.bubble-icon {
  color: #1e3050;
}

@media (max-width: 600px) {
  .icon-skills {
    font-size: 60px;
  }
}
